import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  inputRoot: {
    borderRadius: 4,
    backgroundColor: '#F8F9FA',
    border: '1px solid #BFBFC0',
    '&:hover': {
      backgroundColor: '#F8F9FA', // Keep the background color the same on hover
      border: '1px solid #1D2023', // Change the border color on hover
    },
  },

  shrinkLabel: {
    fontSize: 14,
  },
});

const InputField = (props) => {
  const classes = useStyles();
  const { endAdornment, ...customProps } = props;
  return (
    <TextField
      variant="filled"
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
        endAdornment,
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          shrink: classes.shrinkLabel,
        },
      }}
      fullWidth
      {...customProps}
    />
  );
};

export default InputField;
