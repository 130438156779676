import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'contexts/Auth';
import { StateProvider } from 'contexts/Map/store';
import { UsageCreditProvider } from 'contexts/Credit';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <StateProvider>
        <UsageCreditProvider>
          <Router>
            <App />
          </Router>
        </UsageCreditProvider>
      </StateProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
